<template>
  <div class="report-box-2 intro-y" v-if="isMonActive() && Stats[MonitorId] && Stats[MonitorId].total">
    <div class="box p-5">
      <div class="flex items-center">
        <BarChart2Icon class="pr-2" />{{ $t('Statistics') }} 
        <div class="ml-auto" v-if="MonitorId">{{MonitorId}}</div>
      </div>
      <div class="pos intro-y grid grid-cols-12 gap-5 mt-5" v-if="isLoading || (MonitorId && Stats[MonitorId] && !Stats[MonitorId].total)">
        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-2 mb-5"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div>
      </div>
      <div v-else>
        <div class="text-3xl font-medium mt-2" v-if="Stats[MonitorId]">{{ this.helper.formatScore(Stats[MonitorId].total) }}</div>
        <div class="border-b border-gray-200 flex pb-2 mt-1"><div class="text-gray-600 dark:text-gray-600 text-xs">{{ $t('Analyzed') }} </div></div>
        <div class="mt-2 border-b broder-gray-200" v-if="Lastlog[MonitorId] && Lastlog[MonitorId].length">
          <div class="-mb-1.5 -ml-2.5">
            <Chart
              type="bar"
              :width="StatsChart.width"
              :height="StatsChart.height"
              :data="StatsChart.data"
              :options="StatsChart.options"
              ref-key="reportStats"
            />
          </div>
        </div>
        <div v-if="counter[MonitorId]">
          <div class="text-gray-600 dark:text-gray-600 text-xs border-b border-gray-200 flex mb-2 pb-2 mt-4"><div>{{ $t('Category') }}</div><div class="ml-auto">{{ $t('Total') }}</div></div>
          <div class="flex mb-1" v-for="value in counter[MonitorId]" v-bind:key="value.tag">
            <div v-bind:style="{'border-left': '10px solid '+value.color}" class="pl-2">{{ value.name }}</div>
            <div class="ml-auto">{{ this.helper.formatScore(value.score) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, onMounted , provide, computed, watch } from "vue";
import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/utils/helper";
import { tag as $tag } from "@/model/tag";
//import { monitor as $monitor } from "@/model/monitor";

export default defineComponent({
  props: {
    monitor: {
      type: String,
      default: 'all',
      immediate: true,
    }
  },
  created () {
    this.helper = $h
  },
  setup(props) {

    const isLoading     = ref(true)
    const tagColor      = ref();   
    const colors        = ref([]);  
    const status        = ref({});
    const Stats         = ref({});
    const counter       = ref({});
    const Lastlog       = ref({}); 
    const reportStats   = ref(); 
    const StatsChart    = ref( { width: 100, height: 5, data: {}, options: {} });  
         
    provide("bind[reportStats]", el => { reportStats.value = el; });

    const getCounter = async (m) => {
      let rootRef = 'image_counter'
      if(m!='all')rootRef = m+'/monitor/tag_counter'
      await $_firebase.database().ref(rootRef).on("value",function(snapshot) {
        let realCounter   = snapshot.val()
        if(realCounter){
          Stats.value[m] = { total: 0}
          if(m!='all'){
             if(isMonActive()){
              Object.keys(realCounter).map(async function(tagID){
                  let score = realCounter[tagID];
                  if (typeof counter.value[m] === "undefined")counter.value[m] = {};
                  if (typeof counter.value[m][tagID] === "undefined") {
                    let tagName = await $tag.get(tagID);
                    counter.value[m][tagID] = { tag: tagID, name: tagName.name, score: score, color: colors.value[tagID] }
                    addChartLast(m,tagID);
                  }else{ if(counter.value[m][tagID].score!=score)addChartLast(m,tagID); counter.value[m][tagID].score = score; }
                  Stats.value[m].total += score
              });
             }
          }else{
              if(realCounter !== 'undefined'){
                realCounter.forEach(async (score, tagID) => {
                  if (typeof counter.value[m] === "undefined")counter.value[m] = {};
                  if (typeof counter.value[m][tagID] === "undefined") {
                   let tagName = await $tag.get(tagID.toString());
                    counter.value[m][tagID] = { tag: tagID, name: tagName.name, score: score, color: colors.value[tagID] }
                    addChartLast(m,tagID);
                  }else{ if(counter.value[m][tagID].score!=score)addChartLast(m,tagID); counter.value[m][tagID].score = score;  }
                  Stats.value[m].total += score
                });
              }
          }
        }
      }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    const getLastLog = async (m) => {
     Lastlog.value[m] = []
     if(m!='all'){  for (let i = 0; i < 9; i++) { Lastlog.value[m].push('0') }
     }else{
      Lastlog.value[m] = []
      await $_firebase.firestore().collection('image').orderBy('date', 'desc').limit(20).get()
        .then(snapshot => { snapshot.forEach(doc => { let item  = doc.data(); if(item && item.tag)Lastlog.value[m].push(item.tag) }); if(Lastlog.value[m])Lastlog.value[m].reverse()  });
     }
    }

    const initStatsChart = async (m) => {
      await getLastLog(m)
      //console.log(Lastlog.value[m])
      const reportBarChartData  = Lastlog.value[m]
      const reportBarChartColor = Lastlog.value[m].map(tag => { let tagN = "NOK"; if(tag==1)tagN="OK"; return colors.value[tagN] });
      let barThickness    = 17;
      let maxBarThickness = 17;
      if(props.monitor!='all')barThickness = maxBarThickness = 35;
      StatsChart.value.data = {
        labels: reportBarChartData,
        datasets: [ {
            label: "Cat",
            barPercentage: 0.9,
            barThickness: barThickness,
            maxBarThickness: maxBarThickness,
            minBarLength: 0,
            data: reportBarChartData,
            backgroundColor: reportBarChartColor
          }
        ]
      }
      StatsChart.value.options = {
        legend: { display: false },
        tooltips: { callbacks: { label: async function(tooltipItem) { let tagName = await $tag.get(tooltipItem.value.toString()); return tagName} }},
        scales: {
          xAxes: [{ ticks: { display: false }, gridLines: { display: false } } ],
          yAxes: [ { ticks: { min: 0,  max: 1, display: false,},gridLines: { display: false },suggestedMin: 0,beginAtZero: true}]
        }
      }
    }
  
    const addChartLast = async (m,tagID) => {
      if(reportStats.value){
      
      const chartInstance = reportStats.value.instance;
        const chartConfig   = chartInstance.config;
        //if(chartConfig.data.datasets[0].length){
          //console.log(chartConfig.data.datasets[0].data)
          //if(chartConfig.data.datasets[0].data){
            let newData = tagID;
            if(tagID=="OK"){ newData="2"; }else{ newData="1"; }
            chartConfig.data.datasets[0].data.shift();
            chartConfig.data.datasets[0].data.push(newData);
            chartInstance.update();

            const newColor = colors.value[tagID];
            
            chartConfig.data.datasets[0].backgroundColor.shift();
            chartConfig.data.datasets[0].backgroundColor.push(newColor);
            chartInstance.update();
          //}
       //}
      }
    }

    const getMonitorStatus = async(m) => {
     await $_firebase.database().ref(m+'/monitor/status').on("value",function(snapshot) {
        status.value[m]    = snapshot.val()
        if(!status.value[m]){ Stats.value[m] = { total: 0}; Stats.value[m].total = 0; counter.value[m] = {} }
      }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    const isMonActive = () => { if(props.monitor=='all')return true; if(status.value[props.monitor])return true; }

    const MonitorId = computed( () => { return props.monitor; });

    onBeforeMount( async() => { });

    onMounted( async () => {  
      watchMonitor(props.monitor);
      if(props.monitor=='all')initStatsChart(props.monitor)
    });

    watch(() => props.monitor,async (m) => {  isLoading.value = true; watchMonitor(m) }); 

    const watchMonitor = async (m) => {
      tagColor.value    = await $tag.getColors();
      
      for(let index in tagColor.value){
        let t = tagColor.value[index];
        colors.value[t.tag] = t.color;
      }

      /*
      tagColor.value.forEach(t => { 
        console.log(t)
        colors.value[t.tag] = t.color;
      });
      */
      await getCounter(m)
      if(m!='all'){await getMonitorStatus(m); initStatsChart(m) }
      isLoading.value = false;
    }

    return {
      isLoading,
      Stats,
      StatsChart,
      reportStats,
      counter,
      MonitorId,
      Lastlog,
      isMonActive
    };
  }
});
</script>
