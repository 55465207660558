//import firebase from 'firebase/app'
import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/utils/helper";

const tags = {
  async list(){
    let tags = []
    await $_firebase.firestore().collection('tag').get().then( snapshot => { snapshot.forEach( doc => { let item = doc.data(); item.id = doc.id;  tags.push(item) });  });
    return tags;
  },
  async get(tagID=false){
    if(tagID){
      let mon = $_firebase.firestore().collection('tag').doc(tagID)
      const snapshot = await mon.get();
      let tag = snapshot.data(); tag.id = snapshot.id
      return tag;
    }else{
      let tags = []
      let snapshot = await $_firebase.firestore().collection('tag').get();
      tags = snapshot.docs.map(element => element.data());
      for (const [key,t] of Object.entries(tags)) { tags[key] = t.name; } 
      return tags;
    }
  },
  async getColors(tagID=false){
    //console.log(tagID)
    let tagColor ={}; let selColor = []
    let snapshot  = await $_firebase.firestore().collection('view').doc('tags').get()
    let item = snapshot.data();
    for (const [key,c] of Object.entries(item.tag_color)) {
      let tagkey = key;
      if(!Number.isInteger(key)){
        tagkey = "NOK";
        if(key==1)tagkey= "OK"; 
      }
      if(tagID && tagID==tagkey)selColor.push({ tag: tagkey, color: c }); 
      tagColor[tagkey]= { tag: tagkey, color: c }; 
      //console.log(tagColor)
    } 
    if(tagID)return selColor               
    return tagColor;
  },
  async init() { 
    let tags = []
    let list = await this.list()
    for (const tag of Object.values(list)) {
      let tagName = await this.get(tag.id);
      tags[tag.id] = tagName.name
    }
    return tags;
  },
  async update(tagID,data,msg=false){
    if(tagID)await $_firebase.firestore().collection("tag").doc(tagID).set(data).then(function() { if(msg)$h.Notification(msg) });
  },
};

const install = app => {
  app.config.globalProperties.$tags = tags;
};

export { install as default, tags as tag };
